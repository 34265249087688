import * as React from 'react';
import './header.scss';
import { Link } from 'react-router-dom';
import { getNetworkConnection } from 'sections/networkConnection/redux/selector';
import { AppState } from 'state/app-state';
import { connect } from 'react-redux';
import { appHistory } from 'app-history';
import SCLogo from 'assets/icons/ScoreCard_Logo.svg';
import { confirmAlert } from 'common/Popup/CustomModalPopup';
import authentication from 'helpers/react-msal';
import { getAppInsights } from 'middleware/telemetry/telemetry.service';
import { useEffect, useState } from 'react';
import { getFinancialYears, getPrivileges, getUserProfile, setFinancialYear } from 'sections/common/redux/thunk';
import {
  getAllFinancialYears,
  getAllPrivileges,
  getSelectedFinancialYear,
  getUserPrivileges,
  getUserProfileState
} from 'sections/common/redux/selector';
import routes from 'screens/routes';
import messages from 'translations/messages';
import { Authorised } from 'common/authorisation';
import { Privileges } from 'models/privilege';
import { User } from 'models/user';
import { Info, PowerSettingsNew, Home, Menu } from '@material-ui/icons';
import { downloadResource } from 'services/api-clients';
import { FormattedMessage } from 'react-intl';
import appConfig from 'helpers/config-helper';
import { MinimumFinancialYear } from 'models/calculator';

export interface HeaderProps {
  sideMenuDocked: boolean;
  toggleMenu(): void;
  toggleNotification(): void;
  isNotificationOpen: boolean;
  showNotificationDropDown: boolean;
  isConnected: boolean;
  intl: any;
  allPrivileges: Array<string>;
  allFinancialYears: Array<number>;
  userPrivileges: Array<string>;
  userProfile: User;
  selectedFinancialYear: number;
  getPrivileges: () => void;
  getFinancialYears: () => void;
  getUserProfile: () => void;
  setFinancialYear: (financialYear: number) => void;
  navRef: any;
  isNavOpen: boolean;
  toggleNavBar(): void;
}
const Header = React.memo((props: HeaderProps) => {
  const [openMenu, setOpenMenu] = React.useState(false);
  const ref = React.useRef<any>();
  const toggleMenu = function() {
    setOpenMenu(!openMenu);
  };

  const history = appHistory();
  const { sideMenuDocked, isConnected } = props;
  const menuDockedClassName = sideMenuDocked ? 'header-nav--menu-docked' : 'header-nav--menu-undocked';
  const config = appConfig();

  const onLogout = (e: any) => {
    e.preventDefault();
    confirmAlert({
      intl: props.intl,
      titleKey: 'global.confirmation',
      contentKey: 'global.logout',
      handleConfirm: () => logout()
    });
  };

  const logout = () => {
    const appInsights = getAppInsights();
    if (appInsights) {
      appInsights.flush();
    }
    authentication.signOut();
  };

  const onResourceClickHandle = (event: any, resourceName: string) => {
    event.preventDefault();
    props.toggleNavBar();
    downloadResource(resourceName);
  };

  useEffect(() => {
    const path = history.location.pathname.split('/');
  }, []);

  useEffect(() => {
    if (props.allPrivileges && props.allPrivileges.length === 0) {
      props.getPrivileges();
    }
    if (props.allFinancialYears && props.allFinancialYears.length === 0) {
      props.getFinancialYears();
    }
    if (props.userProfile && props.userProfile.id === -1) {
      props.getUserProfile();
    }
    const financialYear = localStorage.getItem('financialYear');
    if (financialYear) {
      props.setFinancialYear(Number(financialYear));
    }
  }, []);

  useEffect(() => {
    const financialYear = localStorage.getItem('financialYear');
    if (!financialYear && props.allFinancialYears.length > 0) {
      const maxFY = Math.max(...props.allFinancialYears);
      localStorage.setItem('financialYear', maxFY?.toString());
      props.setFinancialYear(maxFY);
      window.location.reload();
    }
  }, [props.allFinancialYears]);

  const redirectToHome = () => {
    history.push('/');
  };

  const changeFinancialYearEvent = (event: any) => {
    const value = event.target.value;
    localStorage.setItem('financialYear', value);
    props.setFinancialYear(value);
    window.location.reload();
  };

  return (
    <nav className={`topbar header-nav ${menuDockedClassName}`}>
      <div ref={props.navRef} className="burgerMenu">
        <div onClick={props.toggleNavBar} className="icon_menu">
          <Menu />
        </div>
        {props.isNavOpen && (
          <div className="submenu">
            <ul className="anotherGroup">
              <p className="groupTitle">{props.intl.formatMessage(messages['menu.administration'])}</p>
              <Authorised privileges={[Privileges.PreviewRole]}>
                <li id="manageRoles">
                  <Link to={routes.roles}>{props.intl.formatMessage(messages['menu.manageRoles'])}</Link>
                </li>
              </Authorised>
              <Authorised privileges={[Privileges.PreviewUserDetails]}>
                <li id="manageUsers">
                  <Link to={routes.users}>{props.intl.formatMessage(messages['menu.manageUsers'])}</Link>
                </li>
              </Authorised>
              <Authorised privileges={[Privileges.EmployeeDetailsPreview]}>
                <li id="manageEmployees">
                  <Link to={routes.employees}>
                    <FormattedMessage id="menu.manageEmployees" />
                  </Link>
                </li>
              </Authorised>
              <Authorised privileges={[Privileges.ReportingScopes]}>
                <li id="manageReport">
                  <Link to={routes.manageReport}>
                    <FormattedMessage id="menu.reportingScopes" />
                  </Link>
                </li>
              </Authorised>
              <p className="groupTitle">{props.intl.formatMessage({ id: 'menu.information' })}</p>
              <Authorised privileges={[Privileges.Reports]}>
                <li id="reports">
                  <Link to={{ pathname: config.REACT_APP_REPORTS_URL }} target="_blank" onClick={props.toggleNavBar}>
                    {props.intl.formatMessage(messages['menu.reports'])}
                  </Link>
                </li>
              </Authorised>
              <p className="groupTitle">{props.intl.formatMessage({ id: 'menu.documents' })}</p>
              <Authorised privileges={[]}>
                <li id="localBonusSchemeRules">
                  <Link to="\" onClick={(event) => onResourceClickHandle(event, 'local-bonus-scheme-rules.pdf')}>
                    {props.intl.formatMessage(messages['menu.localBonusSchemeRules'])}
                  </Link>
                </li>
              </Authorised>
              <Authorised privileges={[]}>
                <li id="financeBonusSchemeRules">
                  <Link to="\" onClick={(event) => onResourceClickHandle(event, 'finance-bonus-scheme-rules.pdf')}>
                    {props.intl.formatMessage(messages['menu.financeBonusSchemeRules'])}
                  </Link>
                </li>
              </Authorised>
              <Authorised privileges={[]}>
                <li id="userGuide">
                  <Link to="\" onClick={(event) => onResourceClickHandle(event, 'user-guide.pdf')}>
                    {props.intl.formatMessage(messages['menu.userGuide'])}
                  </Link>
                </li>
              </Authorised>
              <Authorised
                checkMode="any"
                privileges={[Privileges.Tm1UploadForCalculator, Privileges.CalculatorDataPreview]}
              >
                <p className="groupTitle">{props.intl.formatMessage(messages['menu.calculator'])}</p>
                <Authorised privileges={[Privileges.Tm1UploadForCalculator]}>
                  <li id="tm1Upload">
                    <Link to={routes.tm1Upload}>{props.intl.formatMessage(messages['menu.tm1Upload'])}</Link>
                  </li>
                </Authorised>
                <Authorised privileges={[Privileges.CalculatorDataPreview]}>
                  <li id="calculatorDataPreview">
                    <Link to={routes.calculator}>
                      {props.intl.formatMessage(messages['menu.calculatorDataPreview'])}
                    </Link>
                  </li>
                </Authorised>
              </Authorised>
            </ul>
          </div>
        )}
      </div>
      <div className={'d-md-block logo'} style={{ color: 'white' }} onClick={redirectToHome}>
        <img src={SCLogo} alt="logo" className="header-nav__logo-icon" />
      </div>
      <div className="header-nav__actions headerMargin">
        <div className="col-md-auto">
          <FormattedMessage id="calculator.financialYear" /> &nbsp;
          <select value={props.selectedFinancialYear} onChange={changeFinancialYearEvent}>
            {props.allFinancialYears &&
              props.allFinancialYears.map((year: number, index: number) => (
                <option value={year} key={index}>
                  {year}
                </option>
              ))}
          </select>
        </div>
        <Link to={routes.home} className="header-nav__actions-go-home">
          <Home />
        </Link>
        <Link to="/guidelines" className="header-nav__actions-go-home">
          <Info />
        </Link>
        <Link
          to={'#'}
          className="header-nav__actions-go-home"
          onClick={(e: any) => {
            onLogout(e);
          }}
        >
          <PowerSettingsNew />
        </Link>
        {/* <NavbarToggler id="sidebarCollapse" className={'header-nav__toggle d-none'} /> */}
      </div>
    </nav>
  );
});

const mapStateToProps = (state: AppState) => {
  return {
    isConnected: getNetworkConnection(state),
    allPrivileges: getAllPrivileges(state),
    userPrivileges: getUserPrivileges(state),
    allFinancialYears: getAllFinancialYears(state),
    userProfile: getUserProfileState(state),
    selectedFinancialYear: getSelectedFinancialYear(state)
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    getPrivileges: () => dispatch(getPrivileges()),
    getFinancialYears: () => dispatch(getFinancialYears()),
    getUserProfile: () => dispatch(getUserProfile()),
    setFinancialYear: (financialYear: number) => dispatch(setFinancialYear(financialYear))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Header);

export const actionTypes = {
  SET_FO_LOADING_TRUE: 'SET_FO_LOADING_TRUE',
  RESET_FO_STATE: 'RESET_FO_STATE',
  SEARCH_USERS_SUCCESS: 'SEARCH_USERS_SUCCESS',
  SEARCH_USERS_ERROR: 'SEARCH_USERS_ERROR',
  RESET_OVERLAPPED_FO_STATE: 'RESET_OVERLAPPED_FO_STATE',
  GET_OVERLAPPED_FORMS_SUCCESS: 'GET_OVERLAPPED_FORMS_SUCCESS',
  GET_OVERLAPPED_FORMS_ERROR: 'GET_OVERLAPPED_FORMS_ERROR',
  GET_SCOPE_HIERARCHY_SUCCESS: 'GET_SCOPE_HIERARCHY_SUCCESS',
  GET_SCOPE_HIERARCHY_ERROR: 'GET_SCOPE_HIERARCHY_ERROR',
  GET_MULTI_JURISDICTION_COMMENTS_SUCCESS: 'GET_MULTI_JURISDICTION_COMMENTS_SUCCESS',
  GET_MULTI_JURISDICTION_COMMENTS_ERROR: 'GET_MULTI_JURISDICTION_COMMENTS_ERROR',
  GET_FINANCIAL_FIGURES_SUCCESS: 'GET_FINANCIAL_FIGURES_SUCCESS',
  GET_FINANCIAL_FIGURES_ERROR: 'GET_FINANCIAL_FIGURES_ERROR',
  POST_FINANCIAL_OBJECTIVE_FORM_SUCCESS: 'POST_FINANCIAL_OBJECTIVE_FORM_SUCCESS',
  POST_FINANCIAL_OBJECTIVE_FORM_ERROR: 'POST_FINANCIAL_OBJECTIVE_FORM_ERROR',
  GET_FINANCIAL_OBJECTIVE_SUCCESS: 'GET_FINANCIAL_OBJECTIVE_SUCCESS',
  GET_FINANCIAL_OBJECTIVE_ERROR: 'GET_FINANCIAL_OBJECTIVE_ERROR',
  GET_DEFAULT_FO_DATES_SUCCESS: 'GET_DEFAULT_FO_DATES_SUCCESS',
  GET_DEFAULT_FO_DATES_ERROR: 'GET_DEFAULT_FO_DATES_ERROR',
  GET_BONUSSCHEMES_SUCCESS : 'GET_BONUSSCHEMES_SUCCESS',
  GET_BONUSSCHEMES_ERROR: 'GET_BONUSSCHEMES_ERROR',
  RESET_SCOPEPATHS_STATE: 'RESET_SCOPEPATHS_STATE',
  GET_FINANCE_DIRECTORS_SUCCESS: 'GET_FINANCE_DIRECTORS_SUCCESS',
  GET_FINANCE_DIRECTORS_ERROR: 'GET_FINANCE_DIRECTORS_ERROR',
  GET_VALIDATE_FMC_HIERARCHY_SUCCESS: 'GET_VALIDATE_FMC_HIERARCHY_SUCCESS',
  GET_VALIDATE_FMC_HIERARCHY_ERROR: 'GET_VALIDATE_FMC_HIERARCHY_ERROR',
  GET_FMC_HIERARCHY_SUCCESS: 'GET_FMC_HIERARCHY_SUCCESS',
  GET_FMC_HIERARCHY_ERROR: 'GET_FMC_HIERARCHY_ERROR',  
  GET_NFO_GOALS_SUCCESS: 'GET_NFO_GOALS_SUCCESS',
  GET_NFO_GOALS_ERROR: 'GET_NFO_GOALS_ERROR',
  GET_TWC_FIGURES_SUCCESS : 'GET_TWC_FIGURES_SUCCESS',
  GET_TWC_FIGURES_ERROR: 'GET_TWC_FIGURES_ERROR'
};

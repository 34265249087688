import { FormsSearchState, defaultFormsSearchState } from './state';
import { AppAction } from 'models/action';
import { actionTypes } from './actions';
import { Notify } from 'models/Notify';
import { EventType } from 'models/EventType';
import { actionTypes as financialObjectivesActionTypes } from '../../financialObjective/redux/actions';

export const handlers = {
  [actionTypes.SET_FORMS_SEARCH_LOADING_TRUE]: (state: FormsSearchState, payload: any): FormsSearchState => {
    return {
      ...state,
      loading: true,
      event: payload.event
    };
  },
  [actionTypes.RESET_FORMS_SEARCH_STATE]: (state: FormsSearchState, payload: any): FormsSearchState => {
    return {
      ...state,
      loading: false,
      success: false,
      notify: Notify.none,
      event: EventType.none
    };
  },
  [actionTypes.SEARCH_FORMS_SEARCH_SUCCESS]: (state: FormsSearchState, payload: any): FormsSearchState => {
    return {
      ...state,
      loading: false,
      success: true,
      notify: Notify.none,
      event: EventType.none,
      listOffset: payload.offset,
      listLimit: payload.limit,
      numberOfItems: payload.numberOfItems,
      sort: payload.sort,
      formsList: payload.data,
      appliedFilters: payload.appliedFilters
    };
  },
  [actionTypes.SEARCH_FORMS_SEARCH_ERROR]: (state: FormsSearchState, payload: any): FormsSearchState => {
    return {
      ...state,
      loading: false,
      success: false,
      notify: payload.notify,
      event: EventType.none,
      listOffset: payload.offset,
      listLimit: payload.limit,
      numberOfItems: payload.numberOfItems,
      sort: payload.sort,
      formsList: payload.data,
      appliedFilters: payload.appliedFilters
    };
  },
  [actionTypes.GET_USER_SCOPE_SUCCESS]: (state: FormsSearchState, payload: any): FormsSearchState => {
    return {
      ...state,
      loading: false,
      success: true,
      notify: Notify.none,
      event: EventType.none,
      subSegments: payload.subBusinessSegments,
      divisions: payload.divisions,
      subDivisions: payload.subDivisions,
      teams: payload.teams,
      clients: payload.clients,
      countries: payload.countries
    };
  },
  [actionTypes.GET_USER_SCOPE_ERROR]: (state: FormsSearchState, payload: any): FormsSearchState => {
    return {
      ...state,
      loading: false,
      success: false,
      notify: payload.notify,
      event: EventType.none
    };
  },

  [actionTypes.GET_USER_FMC_SCOPE_SUCCESS]: (state: FormsSearchState, payload: any): FormsSearchState => {
    return {
      ...state,
      loading: false,
      success: true,
      notify: Notify.none,
      event: EventType.none,
      businessSegments: payload.businessSegments,
      globalRegions: payload.globalRegions,
      regions: payload.regions,
      managingOffices: payload.managingOffices,
      clients: payload.clients,
      countries: payload.countries,
      locations: payload.locations,
      lineOfBusinesses: payload.lineOfBusinesses,
      functionalUnits: payload.functionalUnits      
    };
  },
  [actionTypes.GET_USER_FMC_SCOPE_ERROR]: (state: FormsSearchState, payload: any): FormsSearchState => {
    return {
      ...state,
      loading: false,
      success: false,
      notify: payload.notify,
      event: EventType.none
    };
  },



  [financialObjectivesActionTypes.GET_MULTI_JURISDICTION_COMMENTS_SUCCESS]: (
    state: FormsSearchState,
    payload: any
  ): FormsSearchState => {
    return {
      ...state,
      loading: false,
      success: true,
      notify: Notify.none,
      event: EventType.none,
      multiJurisdictionComments: payload
    };
  },
  [financialObjectivesActionTypes.GET_MULTI_JURISDICTION_COMMENTS_ERROR]: (
    state: FormsSearchState,
    payload: any
  ): FormsSearchState => {
    return {
      ...state,
      loading: false,
      success: false,
      notify: payload.notify,
      event: EventType.none,
      multiJurisdictionComments: payload.response
    };
  },
  [actionTypes.GET_SCORECARD_EDITABLE_FOR_SELECTED_YEAR_FLAG_SUCCESS]: (
    state: FormsSearchState,
    payload: any
  ): FormsSearchState => {
    return {
      ...state,
      loading: false,
      success: true,
      notify: Notify.none,
      event: EventType.none,
      isScoreCardEditableForSelectedYear: payload
    };
  },
  [actionTypes.GET_SCORECARD_EDITABLE_FOR_SELECTED_YEAR_FLAG_ERROR]: (
    state: FormsSearchState,
    payload: any
  ): FormsSearchState => {
    return {
      ...state,
      loading: false,
      success: false,
      notify: payload.notify,
      event: EventType.none,
      isScoreCardEditableForSelectedYear: payload.response
    };
  },
  [actionTypes.GET_BONUSSCHEMES_SUCCESS]: (
    state: FormsSearchState,
    payload: any
  ): FormsSearchState => {
    return {
      ...state,
      loading: false,
      success: true,
      notify: Notify.none,
      event: EventType.none,
      bonusSchemes: payload
    };
  },
  [actionTypes.GET_BONUSSCHEMES_ERROR]: (
    state: FormsSearchState,
    payload: any
  ): FormsSearchState => {
    return {
      ...state,
      loading: false,
      success: false,
      notify: payload.notify,
      event: EventType.none
    };
  },
};

const FormsSearchReducer = (state: FormsSearchState = { ...defaultFormsSearchState }, action: AppAction) => {
  return handlers.hasOwnProperty(action.type) ? handlers[action.type](state, action.payload) : state;
};

export default FormsSearchReducer;

export enum EventType {
  none,
  save,
  next,
  comment,
  update,
  warning,
  download,
  approve,
  reject,
  request,
  userList,
  privilegeAdd,
  privilegeRemove,
  addUser,
  removeUser,
  addRole,
  removeRole,
  getData,
  saveAndVerify,
  runYearToDateCalculation,
  runBonusCalculation
}

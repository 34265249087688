export enum Privileges {
  SearchScoreCards = 'SearchScoreCards',
  PreviewUserDetails = 'PreviewUserDetails',
  ManageUserDetails = 'ManageUserDetails',
  ScoreCardSubmit = 'ScoreCardSubmit',
  ScoreCardEditSubmitted = 'ScoreCardEditSubmitted',
  PreviewRole = 'PreviewRole',
  ManageRole = 'ManageRole',
  EmployeeDetailsPreview = 'EmployeeDetailsPreview',
  EmployeeDetailsManage = 'EmployeeDetailsManage',
  Tm1UploadForCalculator = 'Tm1UploadForCalculator',
  CalculatorDataPreview = 'CalculatorDataPreview',
  Reports = 'Reports',
  BonusCalculationResultVerify = 'BonusCalculationResultVerify',
  ReportingScopes = 'ReportingScopes',
  CalculatorDataModify = 'CalculatorDataModify',
  GwsGlobalFigureRead = 'GwsGlobalFigureRead',
  ScoreCardPreview = 'ScoreCardPreview',
  RunYearToDateBonusCalculation = 'RunYearToDateBonusCalculation',
  ChangeFinancialsData = 'ChangeFinancialsData',
  ViewNFOGoals = 'ViewNFOGoals',
  CanEditAssessment = 'CanEditAssessment'
}

export interface IPrivilege {
  code: string;
  name: string;
  description: string;
}

export interface IGroupPrivilege {
  groupPrivilegeHeaderName: string;
  privileges: string[];
}

export const groupPrivilege: IGroupPrivilege[] = [
  {
    groupPrivilegeHeaderName: 'Scorecards',
    privileges: [
      Privileges.GwsGlobalFigureRead,
      Privileges.ScoreCardEditSubmitted,
      Privileges.ScoreCardPreview,
      Privileges.SearchScoreCards,
      Privileges.ScoreCardSubmit,
      Privileges.CanEditAssessment
    ]
  },
  {
    groupPrivilegeHeaderName: 'Roles',
    privileges: [Privileges.ManageRole, Privileges.PreviewRole]
  },
  {
    groupPrivilegeHeaderName: 'Users',
    privileges: [Privileges.ManageUserDetails, Privileges.PreviewUserDetails]
  },
  {
    groupPrivilegeHeaderName: 'Employees',
    privileges: [Privileges.EmployeeDetailsManage, Privileges.EmployeeDetailsPreview]
  },
  {
    groupPrivilegeHeaderName: 'Reports',
    privileges: [Privileges.ReportingScopes, Privileges.Reports]
  },
  {
    groupPrivilegeHeaderName: 'Calculator',
    privileges: [
      Privileges.CalculatorDataPreview,
      Privileges.CalculatorDataModify,
      Privileges.Tm1UploadForCalculator,
      Privileges.BonusCalculationResultVerify,
      Privileges.RunYearToDateBonusCalculation,
      Privileges.ChangeFinancialsData
    ]
  }
];

export const DisabledPrivileges: string[] = [];

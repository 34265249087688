import { EventType } from 'models/EventType';
import { ScopeType } from 'models/IFinancialObjective';
import { IPreviewFO } from 'models/IPreviewFO';
import { IScoreCardEvents } from 'models/IScoreCardEvents';
import { IScoreCardStatus } from 'models/IScoreCardStatus';
import { defaultUser } from 'models/IUser';
import { IScopeNodeName, IScopePath, ScopePathNodes } from 'models/LevelMapping';
import { Notify } from 'models/Notify';
import { defaultNonFinancialObjectives } from 'sections/financialObjective/redux/state';

export interface PreviewFOState {
  loading: boolean;
  success: boolean;
  event: EventType;
  notify: Notify;
  financialObjective: IPreviewFO;
  scopeNodeNames: IScopeNodeName[];
  availableScopePaths: IScopePath[];
  scoreCardEvents: IScoreCardEvents[];
}

export const defaultFO: IPreviewFO = {
  id: -1,

  draft: true,
  user: defaultUser,
  status: IScoreCardStatus.Active,
  reasonForStatusChange: '',
  formPeriod: '',
  startDate: '',
  endDate: '',
  bonusScheme: '',
  createdBy:'',
  createdOn:'',
  updatedBy:'',
  updatedOn:'',
  financialObjectives: {
    level1Label: [],
    level1Figure: '0',
    level1Contribution: 0,
    level2Label: [],
    level2Figure: '0',
    level2Contribution: 0,
    revenueLabel: [],
    revenueFigure: '0',
    revenueContribution: 0,
    tradeWorkingCapitalARLabel: [],
    tradeWorkingCapitalARFigure: '0',
    tradeWorkingCapitalARContribution: 0,
    tradeWorkingCapitalUBRLabel: [],
    tradeWorkingCapitalUBRFigure: '0',
    tradeWorkingCapitalUBRContribution: 0,
    isManualOverride: false,
    manualOverrideComment: null,
    canReadLevel2Figure: true,
    manualOverrideOtherCommentJustification: '',  
    financeDirector: null,    
    isTradeWorkingCapitalApplicable: false
  },
  nonFinancialObjectives: defaultNonFinancialObjectives,
  currentUserCanApprove: false,
  approvals: [],
  canEditFormParts: [],
  selectedScopePathNodes: new ScopePathNodes(),
  scope: {
    scopeType: ScopeType.TotalContract,
    scopeComment: ''
  },
  bonusSchemeId: 0,
  canViewNFOGoals:false,
};

export const defaultPreviewFOState: PreviewFOState = {
  loading: false,
  success: false,
  event: EventType.none,
  notify: Notify.none,
  financialObjective: defaultFO,
  scopeNodeNames: [],
  availableScopePaths: [],
  scoreCardEvents:[]
};

import { AppAction } from 'models/action';
import { actionTypes } from './actions';
import { actionTypes as financialObjectivesActionTypes } from '../../financialObjective/redux/actions';
import { Notify } from 'models/Notify';
import { EventType } from 'models/EventType';
import { defaultFO, defaultPreviewFOState, PreviewFOState } from './state';
import { getScopeLevel, IScopeNodeName, IScopePath, ScopePathNodes } from 'models/LevelMapping';
import { IScopeHierarchyResponse } from 'sections/financialObjective/models.client';
import { INonFinancialObjectives, ScopeType } from 'models/IFinancialObjective';
import { FOFormParts, IPreviewFO } from 'models/IPreviewFO';
import { IFinancialObjectiveResponse, IScoreCardEventsResponse, IScoreCardEventsResultResponse, INonFinancialObjectiveResponse, FileModelResponse } from 'models/models.client';
import { IScoreCardEvents } from 'models/IScoreCardEvents';
import { FileModel } from 'models/fileModel';

const formatAttachments =(input : FileModelResponse[]) =>{
  const attachments: Array<FileModel> = input?.map((fileResponse) => ({ ...fileResponse, content: [] })) ?? [];
  return input? attachments : [{} as FileModel];
}

export const handlers = {
  [actionTypes.SET_PREVIEW_FO_LOADING_TRUE]: (state: PreviewFOState, payload: any): PreviewFOState => {
    return {
      ...state,
      loading: true,
      event: payload.event
    };
  },
  [actionTypes.RESET_PREVIEW_FO_STATE]: (state: PreviewFOState, payload: any): PreviewFOState => {
    return {
      ...state,
      loading: false,
      success: false,
      notify: Notify.none,
      event: EventType.none,
      financialObjective: defaultFO
    };
  },
  [financialObjectivesActionTypes.GET_SCOPE_HIERARCHY_SUCCESS]: (state: PreviewFOState, payload: IScopeHierarchyResponse): PreviewFOState => {
    return {
      ...state,
      loading: false,
      success: true,
      notify: Notify.none,
      event: EventType.none,
      availableScopePaths: payload.scopeHierarchy.map((p) => ({
        subSegment: p.subSegmentCode,
        client: p.clientCode,
        country: p.countryCode,
        division: p.divisionCode,
        vicePres: p.vicePresCode,
        subDivision: p.subDivisionCode,
        team: p.teamCode,
      } as IScopePath)),
      scopeNodeNames: payload.codeNames.map(c => ({ code: c.code, name: c.name, level: getScopeLevel(c.level) } as IScopeNodeName)),
    };
  },
  [financialObjectivesActionTypes.GET_SCOPE_HIERARCHY_ERROR]: (state: PreviewFOState, payload: any): PreviewFOState => {
    return {
      ...state,
      loading: false,
      success: false,
      notify: payload.notify,
      event: EventType.none,
      availableScopePaths: [],
      scopeNodeNames: [],
    };
  },
  [actionTypes.GET_SCORECARD_EVENTS_SUCCESS]: (state: PreviewFOState, payload: IScoreCardEventsResultResponse): PreviewFOState => {
    return {
      ...state,
      loading: false,
      success: true,
      notify: Notify.none,
      event: EventType.none,
      scoreCardEvents: payload.map((p:IScoreCardEventsResponse) => ({
        id: p.id,
        section: p.section,
        subSection: p.subSection,
        field: p.fieldName,
        oldValue: p.oldValue,
        newValue: p.newValue,
        updatedBy: p.modifiedBy,
        updatedOn: p.modifiedDate,
        mask: p.mask
      } as IScoreCardEvents))     
    };
  },
  [actionTypes.GET_SCORECARD_EVENTS_ERROR]: (state: PreviewFOState, payload: any): PreviewFOState => {
    return {
      ...state,
      loading: false,
      success: false,
      notify: payload.notify,
      event: EventType.none,
      scoreCardEvents: []
    };
  },
  [actionTypes.GET_PREVIEW_FO_SUCCESS]: (state: PreviewFOState, payload: IFinancialObjectiveResponse): PreviewFOState => {
    return {
      ...state,
      loading: false,
      success: true,
      notify: Notify.none,
      event: EventType.none,
      financialObjective: {
        ...payload,
        bonusScheme: payload.bonusSchemeName,
        canEditFormParts: payload.canEditFormParts.map(x => x as FOFormParts),
        id: Number(payload.id),
        user: { ...payload.user, id: Number(payload.user.id) },
        scope: { scopeComment: payload.scope.scopeComment, scopeType: payload.scope.scopeType as ScopeType },
        selectedScopePathNodes: new ScopePathNodes({...payload.scope}),
        nonFinancialObjectives: { ...payload.nonFinancialObjectives, attachmentsToDelete: [], attachments: formatAttachments(payload.nonFinancialObjectives.attachments) },
        canViewNFOGoals:payload.canViewNFOGoals
      },
    };
  },
  [actionTypes.GET_PREVIEW_FO_ERROR]: (state: PreviewFOState, payload: any): PreviewFOState => {
    return {
      ...state,
      loading: false,
      success: false,
      notify: payload.notify,
      event: EventType.none
    };
  },
  [actionTypes.POST_APPROVAL_SUCCESS]: (state: PreviewFOState, payload: any): PreviewFOState => {
    return {
      ...state,
      loading: false,
      success: true,
      notify: Notify.success,
      event: payload.event
    };
  },
  [actionTypes.POST_APPROVAL_ERROR]: (state: PreviewFOState, payload: any): PreviewFOState => {
    return {
      ...state,
      loading: false,
      success: false,
      notify: payload.notify,
      event: payload.event
    };
  },
  [actionTypes.DOWNLOAD_SUCCESS]: (state: PreviewFOState, payload: any): PreviewFOState => {
    return {
      ...state,
      loading: false,
      success: true,
      notify: Notify.none
    };
  },
  [actionTypes.DOWNLOAD_ERROR]: (state: PreviewFOState, payload: any): PreviewFOState => {
    return {
      ...state,
      loading: false,
      success: false,
      notify: payload.notify
    };
  },
};

const PreviewFOReducer = (state: PreviewFOState = { ...defaultPreviewFOState }, action: AppAction) => {
  return handlers.hasOwnProperty(action.type) ? handlers[action.type](state, action.payload) : state;
};

export default PreviewFOReducer;
